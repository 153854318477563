import {
  CobieneLogoLarge,
  CollageAlfredoBonifaz,
  CollageAlfredoBonifazCinco,
  CollageAlfredoBonifazCuatro,
  CollageAlfredoBonifazDos,
  CollageAlfredoBonifazSeis,
  CollageAlfredoBonifazTres,
  CollageEmilio,
  CollageEmilioDos,
  CollageEmilioTres,
  CollageEmilioCuatro,
  CollageEmilioCinco,
  CollageEmilioSeis,
  CollageEmilioSiete,
  CollagePedroRuiz,
  CollagePedroRuizCinco,
  CollagePedroRuizCuatro,
  CollagePedroRuizDos,
  CollagePedroRuizSeis,
  CollagePedroRuizTres,
  LaEsperanza,
  LasPalmas,
  LasPalmasDos,
  LasPalmasTres,
  PrimerosPasos,
  CollageEsperanzaDos,
  CollageEsperanzaTres,
  CollageEsperanzaCuatro,
  CollageEsperanzaCinco,
  Inst1,
  Inst10,
  Inst11,
  Inst12,
  Inst13,
  Inst14,
  Inst15,
  Inst16,
  Inst17,
  Inst18,
  Inst19,
  Inst2,
  Inst20,
  Inst21,
  Inst22,
  Inst3,
  Inst4,
  Inst5,
  Inst6,
  Inst7,
  Inst8,
  Inst9,
  JaeAbout,
  JaeCarousel,
  JaeCarousel2,
  JaeOthers,
  JoseJoaquin,
  Jesusni,
  CollageJesus,
  CollageJesusDos,
  CollageJesusTres,
  CollageJesusCuatro,
  CollageJesusCinco,
  CollageJesusSeis,
  CollageJesusSiete,
  CollageJesusOcho,
  CollageJesusNueve,
  CollageJesusDiez,
  CollageEsperanza,
  Matellini,
  CollageMatellini,
  CollageMatelliniDos,
  CollageMatelliniTres,
  CollageMatelliniCuatro,
  CollageMatelliniCinco,
  CollageSantaIsabel,
  CollageSantaIsabelDos,
  CollageSantaIsabelTres,
  CollageSantaIsabelCuatro,
  CollageSantaIsabelCinco,
  CollageSantaIsabelSeis,
  CollageSantaIsabelSiete,
  CollageAndresTumbes,
  CollageAndresTumbesDos,
  CollageAndresTumbesTres,
  CollageAndresTumbesCuatro,
  CollageAndresTumbesCinco,
  CollageMiguelCortez,
  CollageMiguelCortezDos,
  CollageMiguelCortezTres,
  CollageMiguelCortezCuatro,
  CollageMiguelCortezCinco,
  CollageInclan,
  CollageInclanDos,
  CollageInclanTres,
  CollageInclanCuatro,
  CollageInclanCinco,
  CollageInclanSeis,
  CollageBolognesi,
  CollageBolognesiDos,
  CollageBolognesiTres,
  CollageBolognesiCuatro,
  CollageBolognesiCinco,
  CollageNuestrosHeroes,
  CollageNuestrosHeroesDos,
  CollageNuestrosHeroesTres,
  CollageNuestrosHeroesCuatro,
  CollageNuestrosHeroesCinco,
  CollageNuestrosHeroesSeis,
  CollageAndresLocumba,
  CollageAndresLocumbaDos,
  CollageAndresLocumbaTres,
  CollageAndresLocumbaCuatro,
  CollageRamonZavala,
  CollageRamonZavalaDos,
  CollageRamonZavalaTres,
  CollageRamonZavalaCuatro,
  CollageTarapacaIquitos,
  CollageTarapacaIquitosDos,
  CollageTarapacaIquitosTres,
  CollageTarapacaIquitosCuatro,
  CollageTarapacaIquitosCinco,
  CollageMontessori,
  CollageMontessoriDos,
  CollageMontessoriTres,
  CollageAndresPuno,
  CollageAndresPuno2,
  CollageAndresPuno3,
  CollageAndresPuno4,
  CollageArequipa,
  CollageArequipa2,
  CollageArequipa3,
  CollageArequipa4,
  CollageArequipa5,
  CollageArequipa6,
  CollageArequipa7,
  CollageArequipa8,
  CollageAndresIquitos,
  CollageAndresIquitos2,
  CollageAndresIquitos3,
  CollageAndresIquitos4,
  CollageAndresIquitos5,
  CollageAndresIquitos6,
  CollageAndresIquitos7,
  CollageAndresIquitos8,
  JoseJoaquin2,
  JoseJoaquin3,
  JoseJoaquin4,
  PrimerosPasos2,
  PrimerosPasos3,
} from "../images";
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faGlobe,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
export const jaeConfig = {
  helmet: {
    title: "Jefatura de Apoyo Educativo",
    link: "https://jae.cobiene.mil.pe",
    description: "Jefatura de Apoyo Educativo",
    keywords: "educación,servicios,convenios",
  },
  header: {
    name: "jae",
    title: "Jefatura de Apoyo Educativo",
    logoImg: CobieneLogoLarge,
    menuList: [
      { id: "about-us", name: "NOSOTROS" },
      { id: "ColegiosdeLima", name: "Colegios Lima" },
      { id: "ColegiosdeProvincia", name: "Colegios Provincia" },
      { id: "contact", name: "Contacto" },
    ],
  },
  main: {
    sectionCarousel: [
      {
        title: "APOYO EDUCATIVO",
        image: [JaeCarousel],
        buttons: [
          // {
          //   type: "primary",
          //   id: "about-us",
          //   title: "Nosotros",
          // },
          // {
          //   type: "secondary",
          //   id: "requirements",
          //   title: "Requisitos",
          // },
        ],
      },
      {
        title: "APOYO EDUCATIVO",
        image: [JaeCarousel2],
        buttons: [
          // {
          //   type: "primary",
          //   id: "about-us",
          //   title: "Nosotros",
          // },
          // {
          //   type: "secondary",
          //   id: "contact",
          //   title: "Contacto",
          // },
        ],
      },
    ],
    sectionAbout: {
      title: "Nosotros",
      subTitle:
        "Planificamos y evaluamos, los procesos educativos e instrucción.",
      descriptions: [
        "La Jefatura de Apoyo Educativo controla el funcionamiento de la gestión pedagógica y académica, además, apoya la gestión de recursos humanos y materiales de las IIEE del Ejército, impulsando la calidad educativa la cual debe articularse en base a los valores institucionales, en concordancia con los objetivos del Sistema Educativo Nacional y del Comando de Bienestar del Ejército.",
        "El Comando bienestar del ejército cuenta con 23 instituciones educativas a nivel nacional.",
      ],
      images: [JaeAbout],
    },
    sectionCollages: {
      collages: [
        {
          images: [JaeOthers],
        },
      ],
    },
    sectionSchoolsLima: {
      id: "ColegiosdeLima",
      title: "Colegios de Lima",
      cards: [
        {
          image: Inst1,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "PEDRO RUIZ GALLO",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          descriptionLarge: [
            {
              images: [
                // PedroRuiz,
                CollagePedroRuiz,
                CollagePedroRuizDos,
                CollagePedroRuizTres,
                CollagePedroRuizCuatro,
                CollagePedroRuizCinco,
                CollagePedroRuizSeis,
              ],
            },
          ],
          button: "",
        },
        {
          image: Inst2,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "Tte. Crl Alfredo Bonifaz",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                // AlfredoBonifaz,
                CollageAlfredoBonifaz,
                CollageAlfredoBonifazDos,
                CollageAlfredoBonifazTres,
                CollageAlfredoBonifazCuatro,
                CollageAlfredoBonifazCinco,
                CollageAlfredoBonifazSeis,
              ],
            },
          ],
        },
        {
          image: Inst3,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "6005 GRAL EMILIO SOYER CABERO",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                // EmilioSoyer,
                CollageEmilio,
                CollageEmilioDos,
                CollageEmilioTres,
                CollageEmilioCuatro,
                CollageEmilioCinco,
                CollageEmilioSeis,
                CollageEmilioSiete,
              ],
            },
          ],
        },
        {
          image: Inst4,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "CRL JOSE JOAQUIN INCLAN",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [JoseJoaquin, JoseJoaquin2, JoseJoaquin3, JoseJoaquin4],
            },
          ],
        },
        {
          image: Inst5,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "JESUS NIÑO",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                Jesusni,
                CollageJesus,
                CollageJesusDos,
                CollageJesusTres,
                CollageJesusCuatro,
                CollageJesusCinco,
                CollageJesusSeis,
                CollageJesusSiete,
                CollageJesusOcho,
                CollageJesusNueve,
                CollageJesusDiez,
              ],
            },
          ],
        },
        {
          image: Inst6,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "LA ESPERANZA",
          title3: "CUNA - JARDIN",
          button: "",
          descriptionLarge: [
            {
              images: [
                LaEsperanza,
                CollageEsperanza,
                CollageEsperanzaDos,
                CollageEsperanzaTres,
                CollageEsperanzaCuatro,
                CollageEsperanzaCinco,
              ],
            },
          ],
        },
        {
          image: Inst7,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "MATELLINI",
          title3: "CUNA - JARDIN",
          button: "",
          descriptionLarge: [
            {
              images: [
                Matellini,
                CollageMatellini,
                CollageMatelliniDos,
                CollageMatelliniTres,
                CollageMatelliniCuatro,
                CollageMatelliniCinco,
              ],
            },
          ],
        },
        {
          image: Inst8,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "Primeros Pasos",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [PrimerosPasos, PrimerosPasos2, PrimerosPasos3],
            },
          ],
        },
        {
          image: Inst9,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "Las Palmas",
          title3: "INICIAL",
          button: "",
          descriptionLarge: [
            {
              images: [LasPalmas, LasPalmasDos, LasPalmasTres],
            },
          ],
        },
        {
          image: Inst10,
          title: "Institución educativa Pública de Gestión Privada",
          title2: "SANTA ISABEL",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageSantaIsabel,
                CollageSantaIsabelDos,
                CollageSantaIsabelTres,
                CollageSantaIsabelCuatro,
                CollageSantaIsabelCinco,
                CollageSantaIsabelSeis,
                CollageSantaIsabelSiete,
              ],
            },
          ],
        },
        {
          image: Inst11,
          title: "Inicial",
          title2: "Corazon de Jesus",
          button: "",
        },
      ],
    },
    sectionProvinceSchools: {
      id: "ColegiosdeProvincia",
      title: "Colegios de Provincia",
      cards: [
        {
          image: Inst14,
          title: "Institución Educativa",
          title2: "MCAL ANDRES AVELINO CACERES - TUMBES",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageAndresTumbes,
                CollageAndresTumbesDos,
                CollageAndresTumbesTres,
                CollageAndresTumbesCuatro,
                CollageAndresTumbesCinco,
              ],
            },
          ],
        },
        {
          image: Inst20,
          title: "Institución Educativa Privada",
          title2: "TTE MIGUEL CORTES - SULLANA",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageMiguelCortez,
                CollageMiguelCortezDos,
                CollageMiguelCortezTres,
                CollageMiguelCortezCuatro,
                CollageMiguelCortezCinco,
              ],
            },
          ],
        },
        {
          image: Inst15,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "CRL JOSE JOAQUIN INCLAN - PIURA  ",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageInclan,
                CollageInclanDos,
                CollageInclanTres,
                CollageInclanCuatro,
                CollageInclanCinco,
                CollageInclanSeis,
              ],
            },
          ],
        },
        {
          image: Inst22,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "40159  AREQUIPA” - AREQUIPA",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageArequipa,
                CollageArequipa2,
                CollageArequipa3,
                CollageArequipa4,
                CollageArequipa5,
                CollageArequipa6,
                CollageArequipa7,
                CollageArequipa8,
              ],
            },
          ],
        },
        {
          image: Inst13,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "ANDRES AVELINO CACERES - PUNO",
          title3: "INICIAL - PRIMARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageAndresPuno,
                CollageAndresPuno2,
                CollageAndresPuno3,
                CollageAndresPuno4,
              ],
            },
          ],
        },
        {
          image: Inst21,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "CRL FRANCISCO BOLONESI - CUZCO",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageBolognesi,
                CollageBolognesiDos,
                CollageBolognesiTres,
                CollageBolognesiCuatro,
                CollageBolognesiCinco,
              ],
            },
          ],
        },
        {
          image: Inst16,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "NUESTROS HEROES DE LA GUERRA DEL PACIFICO - TACNA",
          title3: "INICIAL - PRIMARIA - SECUNDARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageNuestrosHeroes,
                CollageNuestrosHeroesDos,
                CollageNuestrosHeroesTres,
                CollageNuestrosHeroesCuatro,
                CollageNuestrosHeroesCinco,
                CollageNuestrosHeroesSeis,
              ],
            },
          ],
        },
        {
          image: Inst12,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "ANDRES AVELINO CACERES - LOCUMBA",
          title3: "INICIAL - PRIMARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageAndresLocumba,
                CollageAndresLocumbaDos,
                CollageAndresLocumbaTres,
                CollageAndresLocumbaCuatro,
              ],
            },
          ],
        },
        {
          image: Inst12,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "CRL RAMON ZAVALA SUAREZ - LOCUMBA",
          title3: "INICIAL",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageRamonZavala,
                CollageRamonZavalaDos,
                CollageRamonZavalaTres,
                CollageRamonZavalaCuatro,
              ],
            },
          ],
        },
        {
          image: Inst17,
          title: "Institución Educativa Pública de Gestión Privada",
          title2: "MCAL ANDRES AVELINO CACERES - Iquitos",
          title3: "INICIAL",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageAndresIquitos,
                CollageAndresIquitos2,
                CollageAndresIquitos3,
                CollageAndresIquitos4,
                CollageAndresIquitos5,
                CollageAndresIquitos6,
                CollageAndresIquitos7,
                CollageAndresIquitos8,
              ],
            },
          ],
        },
        {
          image: Inst18,
          title: "Institución Educativa",
          title2: "TARAPACA - IQUITO",
          title3: "INICIAL - PRIMARIA",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageTarapacaIquitos,
                CollageTarapacaIquitosDos,
                CollageTarapacaIquitosTres,
                CollageTarapacaIquitosCuatro,
                CollageTarapacaIquitosCinco,
              ],
            },
          ],
        },
        {
          image: Inst19,
          title: "Institución Educativa",
          title2: "N° 169 MARIA MONTESSORI - IQUITOS",
          title3: "INICIAL",
          button: "",
          descriptionLarge: [
            {
              images: [
                CollageMontessori,
                CollageMontessoriDos,
                CollageMontessoriTres,
              ],
            },
          ],
        },
      ],
    },
  },
  sectionContact: {
    title: "CONTACTO COBIENE",
    subtitle: "No dudes en comunicarte",
    socialsRed: [
      {
        href: "https://api.whatsapp.com/send/?phone=+51929054672&text=Buenos+dias+tengo+una+consulta&app_absent=0",
        icon: faWhatsapp,
        name: "Whatsapp",
        value: "929054672",
      },
      {
        href: "mailto:contacto@cobiene.mil.pe",
        icon: faEnvelope,
        name: "Email",
        value: "contacto@cobiene.mil.pe",
      },
      {
        href: "https://www.facebook.com/profile.php?id=61555409192993&mibextid=sCpJLy",
        icon: faFacebook,
        name: "Facebook",
      },
      { href: "tel:941801827", icon: faMobile, name: "Celular" },
      {
        href: "https://goo.gl/maps/Bh9esULuN4LvV64n6",
        icon: faGlobe,
        name: "Dirección",
        value: "Lima-Perú",
      },
    ],
  },
  footer: {},
};
